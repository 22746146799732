
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































































$card-spacing-mobile: $spacing * 1.4;
$card-spacing-desktop: $spacing * 3;

.archive {
  width: 100%;
  border-radius: 6rem;
}

.archive-inner {
  background: $c-white;
  border-radius: 4rem 4rem 0 0;
}

.g-baseline {
  @include mq($until: l) {
    padding: 0 col(2, 20);
  }
}

::v-deep .g-content__main {
  @include mq($until: m) {
    padding-top: $spacing;
  }
}

.archive__listing__intro {
  @extend %text-center;

  margin-right: auto;
  margin-left: auto;
  padding: 0 col(1, 18);

  @include mq(l) {
    max-width: 22em;
    margin-top: 0;
    padding: 0;
  }
}

.archive__listing__list {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;

  @include mq(s) {
    display: flex;
    flex-wrap: wrap;
  }
}

.archive__listing__item {
  & + & {
    margin-top: $card-spacing-mobile;
  }

  .card {
    width: 100%;
    height: 100%;
  }

  @include mq(s) {
    flex-basis: calc(calc(100% - #{$card-spacing-mobile}) * 0.5);

    & + & {
      margin-top: 0;
    }

    &:nth-child(2n) {
      margin-left: $card-spacing-mobile;
    }

    &:nth-child(n + 3) {
      margin-top: $card-spacing-mobile;
    }
  }

  @include mq(l) {
    flex-basis: calc(calc(100% - #{$card-spacing-desktop * 2}) / 3);
    margin-left: $card-spacing-desktop;

    &:nth-child(2n) {
      margin-left: $card-spacing-desktop;
    }

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &:nth-child(n + 3) {
      margin-top: 0;
    }

    &:nth-child(n + 4) {
      margin-top: $card-spacing-desktop;
    }
  }
}

.archive__listing__footer {
  text-align: center;
}
